import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../component/Sidebar";
import Header from "../component/Header";
import {
  Paperclip,
  Send,
  X,
  ThumbsUp,
  ThumbsDown,
  MessageCircle,
  ArrowUp,
  Upload
} from "react-feather";
import { useParams, useLocation } from "react-router-dom";
import Googledocs from "../images/Googledocs.png";
import PDF from "../images/PDF.png";
import LOGO from "../images/logo.svg";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import info from "../images/info.svg";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { HOST } from "../Api/api";


function Preview() {
  const { gpt_name } = useParams();
  const location = useLocation();
  const { logoPath } = location.state || {};
  const { selectedImage } = location.state || {};
  const { user_type } = location.state || {};
  const [userQuery, setUserQuery] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState("knowledge_graph");
  const [temperature, setTemperature] = useState(0.5);
  const [transformer, setTransformer] = useState("openai")
  const [showModal, setShowModal] = useState(false);
  // for typing effect
  const [typingText, setTypingText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [fileUploading, setfileUploading] = useState(false);
  const [urlloading, setUrlloading] = useState(false);
  const [url, setUrl] = useState("");
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-zA-Z0-9$_.+!*'(),;&=-]|%[0-9a-fA-F]{2})+(:([a-zA-Z0-9$_.+!*'(),;&=-]|%[0-9a-fA-F]{2})+)?@)?)" + // user:password authentication
    "((([a-zA-Z0-9-.]|%[0-9a-fA-F]{2})+\\.[a-zA-Z]{2,})" + // domain name
    "|((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
    "(\\:\\d+)?(\\/([a-zA-Z0-9$_.+!*'(),;&=-]|%[0-9a-fA-F]{2})*)*" + // port and path
    "(\\?([a-zA-Z0-9$_.+!*'(),;&=-]|%[0-9a-fA-F]{2})+)?(#[a-zA-Z0-9$_.+!*'(),;&=-]*)?$" // query string or fragment
  );
  // Store abort controllers for each file upload
  const abortControllersRef = useRef({});
  //for feedback
  // const [showFeedback, setShowFeedback] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState({});

  //for comment
  const [comment, setComment] = useState({});
  const [commentData, setCommentData] = useState("");
  //for file
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    file_name: [],
    unique_file_name: [],
  });
  const [progress, setProgress] = useState({});
  const [apiError, setApiError] = useState("");
  const [errors, setErrors] = useState({});
  //automatic scroll down
  const messagesEndRef = useRef(null);
  // for maintaining session id
  const [_id, setId] = useState("");
  const [sessionId, setSessionId] = useState("");
  //maintaining id for feedback
  // const[feedbackId, setFeedbackId] = useState("");

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  // Scroll to bottom function
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleInputChange = (event) => {
    setUserQuery(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };
  //delete chat history
  const delete_history = (status) => {
    if (status === 1) {
      setChatHistory([]);
      setId("");
      setFeedback({});
      setComment({});
      setUserQuery("");
    }
  };

  //New Chat
  const handleNewChat = () => {
    setId("");
    setChatHistory([]);
    setFeedback({});
    setComment({});
    setUserQuery("");
    // setShowFeedback(false);
  };

  //chat History
  const handleHistoryItemClick = async (item) => {
    // setShowFeedback(false);
    setFeedback({});
    setComment({});

    setId(item._id);
    // console.log(item);
    try {
      const response = await axios.post(
        HOST + "/history_retrieve",
        { _id: item._id, gpt_name: gpt_name },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // const user_text = response.data[0].user_query;
      // const gptresponse = response.data[0].response;
      // console.log(response.data.response);
      // console.log(gptresponse);
      setChatHistory(response.data.response);
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  //feedback api
  const handleButtonClick = (chatId, option) => {
    console.log(chatId);
    let rating;
    if (option === "thumbsUp") {
      rating = feedback[chatId] === "thumbsUp" ? 3 : 5;
    } else if (option === "thumbsDown") {
      rating = feedback[chatId] === "thumbsDown" ? 3 : 1;
    }
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [chatId]: prevFeedback[chatId] === option ? null : option,
    }));
    let data = {
      _id: chatId,
      gpt_name: gpt_name,
      rating: rating,
    };
    console.log(data);
    fetch(HOST + "/user_feedback", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch(() => {
        toast.error("OOPs Something went Wrong");
      });
  };

  const simulateTyping = (text, _id) => {
    setIsTyping(true);
    setTypingText(''); // Reset typingText before starting the interval

    let index = 0;
    let lastScrollTime = Date.now();

    const interval = setInterval(() => {
      if (index < text.length) {
        const nextChar = text.charAt(index);
        setTypingText((prev) => prev + nextChar);

        const now = Date.now();
        if (now - lastScrollTime > 50) { // Adjust the throttle timing here
          requestAnimationFrame(scrollToBottom);
          lastScrollTime = now;
        }

        index++;
      } else {
        clearInterval(interval);
        setIsTyping(false);
        setChatHistory(prevHistory => [...prevHistory, { gpt_text: text }]);
        setChatHistory(prevHistory => [...prevHistory, { _id: _id }]);
        requestAnimationFrame(scrollToBottom); // Ensure final scroll to bottom
      }
    }, 5); // Adjust typing speed here (in milliseconds)
  };

  const handleSend = async () => {
    if (userQuery.trim() === "") return;
    // setChatHistory([...chatHistory, { sender: 'user', text: userQuery }]);
    setChatHistory([...chatHistory, { user_text: userQuery }]);
    setLoading(true);
    const data = { user_query: userQuery, _id: _id, model_type: modal, temperature: temperature,ai_model :transformer};
    // console.log(data);
    // setShowFeedback(false);
    // setComment(false);
    // setSelectedOption(null);
    axios.post(HOST + `/query_response/${gpt_name}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then((response) => {
        setTypingText('');
        simulateTyping(response.data.message, response.data.insertedId);
        // setChatHistory(prevHistory => [...prevHistory, { sender: 'GPT', text: response.data.message }]);
        if (response.data._id !== _id) {
          setSessionId(response.data._id);
          setId(response.data._id);
        }
        // setFeedbackId(response.data.insertedId);
        // setChatHistory(prevHistory => [...prevHistory, { gpt_text: response.data.message }]);
        // setChatHistory(prevHistory => [...prevHistory, { _id: response.data.insertedId }]);
        // setShowFeedback(true);
      })
      .catch((error) => {
        console.error('Error fetching response:', error.response?.data?.message);
        // setChatHistory(prevHistory => [...prevHistory, { sender: 'GPT', text: 'Error fetching response' }]);
        setChatHistory(prevHistory => [...prevHistory, { gpt_text: 'Failed to generate response' }]);
        // setChatHistory(prevHistory => [...prevHistory, { _id: response.data._id }]);
        // setShowFeedback(true);
      })
      .finally(() => {
        setLoading(false);
      });
    setUserQuery("");
    setFileUpload(false);
    setProgress({});
  };

  const handleFileChange = (e) => {
    setFileUpload(true);
    setShowModal(false);
    const files = e.target.files;
    if (files.length > 6) {
      setErrors({
        ...errors,
        file_limit: "You can upload a maximum of six files",
      });
      return;
    }
    Array.from(files).forEach((file) => {
      if (file.size > 5 * 1024 * 1024) {
        toast.error(`File ${file.name} is too large. Maximum allowed size is 5 MB.`);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
        return;
    }
      setProgress((prevProgress) => ({ ...prevProgress, [file.name]: 0 }));
      uploadFile(file);
    });
  };

  const uploadFile = (file) => {
    setfileUploading(true); // Start the loader
    const formData = new FormData();
    formData.append("gpt_name", gpt_name);
    formData.append("files", file);
    // Create an AbortController instance for each upload
    const abortController = new AbortController();
    const signal = abortController.signal;

    axios
      .post(
        HOST + "/upload_files",
        formData,
        {
          headers: {
            Accept: "application/json",
          },
          signal, 
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: percentCompleted,
            }));
          },
        }
      )
      .then((response) => {
        setFormData((prevData) => ({
          ...prevData,
          file_name: [...prevData.file_name, response.data.filename],
          unique_file_name: [
            ...prevData.unique_file_name,
            response.data.unique_filename,
          ],
        }));
        delete abortControllersRef.current[file.name];
        console.log("File uploaded successfully:", response.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Upload canceled");
        } else {
          setFileUpload(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
        toast.error("Error uploading file:" || error.response?.data?.message)
        console.error("Error uploading file:", error.response?.data?.message)
      }}
      )
      .finally(() => {
        setfileUploading(false);
      });
      // Store the AbortController to be able to cancel the upload later
    abortControllersRef.current[file.name] = abortController;
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const handleFileDelete = (fileName) => {
    if (abortControllersRef.current[fileName]) {
      // Cancel the ongoing upload
      abortControllersRef.current[fileName].abort();
      delete abortControllersRef.current[fileName];
      setProgress((prevProgress) => {
        const newProgress = { ...prevProgress };
        delete newProgress[fileName];
        return newProgress;
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      return;
    }
    const index = formData.file_name.indexOf(fileName);
    const uniqueFileName = formData.unique_file_name[index];
    axios
      .delete(
        HOST + `/delete_file/${gpt_name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          data: { unique_file_name: uniqueFileName },
        }
      )
      .then(() => {
        setFormData((prevData) => ({
          ...prevData,
          file_name: prevData.file_name.filter((name) => name !== fileName),
          unique_file_name: prevData.unique_file_name.filter(
            (name) => name !== uniqueFileName
          ),
        }));
        setProgress((prevProgress) => {
          const newProgress = { ...prevProgress };
          delete newProgress[fileName];
          return newProgress;
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      })
      .catch((error) => {
        toast.error("Error deleting file:" || error.response?.data?.message);
        setApiError("Error deleting file");
      });
  };

  const getFileIcon = (fileName) => {
    if (fileName.endsWith(".pdf")) {
      return PDF;
    } else if (fileName.endsWith(".doc") || fileName.endsWith(".docx")) {
      return Googledocs;
    }
  };

  // handling comment
  const handleCommentClick = (chatId) => {
    setComment((prevState) => ({
      ...prevState,
      [chatId]: !prevState[chatId],
    }));
  };

  const handleComment = async (feedbackId) => {
    console.log(commentData);
    try {
      const response = await axios.post(
        HOST + "/user_comments",
        { _id: feedbackId, gpt_name: gpt_name, comment: commentData },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response.data.message === "Success") {
        setCommentData("");
        setComment((prevState) => ({
          ...prevState,
          [feedbackId]: false,
        }));
      } else {
        toast.error("Failed to submit feedback");
      }
    } catch (error) {
      toast.error("something went wrong");
    } finally {
      setComment((prevState) => ({
        ...prevState,
        [feedbackId]: false,
      }));
    }
  };

  const handleScrape = async () => {
    if (url.trim() === "") return;
    if (!urlPattern.test(url)) {
      toast.error("Invalid URL");
      return;
    }
    setUrlloading(true);
    try {
      const response = await axios.post(HOST + "/web_scraping", { gpt_name: gpt_name, url: url }, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      });
      if (response.status === 200) {
        toast.success("Scraping completed successfully! The data has been extracted.");
      }
    }
    catch (error) {
      // console.log(error.message || error.response?.data);
      // console.log(error.response?.data?.message)
      toast.error(error.response?.data?.message || "Scraping failed. Please check the URL or try again later");
    }
    finally {
      setUrlloading(false);
      setUrl("");
      setShowModal(false);
    }

  }
  //definfining temperature
  const temperatureOptions = [0.1, 0.2, 0.3, 0.5, 0.6, 0.7, 0.8, 0.9];
  
  return (
    <div className="d-flex flex-row">
      <div className={`p-0 ${isSidebarVisible ? "d-block" : "d-none d-lg-block"
        }`}
      >
        <Sidebar
          gpt_name={gpt_name}
          sessionid={sessionId}
          onNewChat={handleNewChat}
          onHistoryItemClick={handleHistoryItemClick}
          onDelete={delete_history}
        />
      </div>
      <div className=" p-0 flex-grow-1">
        <div className="">
          <Header
            header_name={gpt_name}
            logo_header={logoPath || selectedImage || LOGO}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div className="d-flex flex-row justify-content-between mt-1">
          <div className="d-flex flex-column preview-select-width mx-2">
            <div>
              <p className="preview-dropdown m-0">Select Model</p>
            </div>
            <div className="mt-1">
              <select
                className="form-select model-temp"
                name="modal"
                value={modal}
                onChange={(e) => {
                  setModal(e.target.value);
                }}
              >
                <option disabled selected value="" className="d-none">
                  Select Model
                </option>
                <option value="knowledge_graph">Knowledge Graph</option>
                <option value="knowledge_graph_and_AI">
                  Knowledge Graph with AI
                </option>
                <option value="AI">
                  AI
                </option>
              </select>
            </div>
          </div>
          <div className="d-flex flex-column preview-select-width mx-2">
            <div>
              <p className="preview-dropdown m-0">Select Transformer</p>
            </div>
            <div className="mt-1">
              <select
                className="form-select model-temp"
                name="modal"
                value={transformer}
                onChange={(e) => {
                  setTransformer(e.target.value);
                }}
              >
                <option disabled selected value="" className="d-none">
                  Select Transformer
                </option>
                <option value="openai">OpenAI</option>
                <option value="gemini">
                  Gemini
                </option>
                <option className="d-none" value="llama">
                  Llama
                </option>
              </select>
            </div>
          </div>
          <div className="d-flex flex-column preview-select-width mx-2">
            <div>
              <p className="preview-dropdown m-0">Select Temperature <img className="info-icon mx-2"
                style={{ cursor: "pointer" }} data-tooltip-id='temp'
                data-tooltip-content='Lower temperatures make responses more focused, higher temperatures increase creativity.'
                data-tooltip-place="bottom" src={info} alt="" /></p>
              <Tooltip id="temp" />
            </div>
            <div className="mt-1">
              <select
                className="form-select model-temp"
                name="account_type"
                value={temperature}
                onChange={(e) => {
                  setTemperature(Number(e.target.value));
                }}
              >
                <option disabled selected value="" className="d-none">
                  Temperature
                </option>
                {temperatureOptions.map((temp, index) => (
                  <option key={index} value={temp}>
                    {temp}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="chat-main mx-0 mx-lg-5 mx-md-5 px-2 px-lg-5 overflow-y-scroll">
          <div className="mt-2">
            {chatHistory.map((chat, index) => (
              <div key={index}>
                <div
                  className={`d-flex ${chat.user_text
                    ? "justify-content-end"
                    : "justify-content-start deepkak-ke-project-ki-width"
                    }`}
                  key={index}
                >
                  <div
                    className={`sender-chat font-16 ${chat.user_text
                      ? "text-end p-3 rounded-5 bg-f0 mt-3"
                      : "text-start d-flex flex-row mt-3"
                      }`}
                  >
                    {chat.user_text && (
                      <div className="user-chat">
                        <span
                          className="mx-3"
                          dangerouslySetInnerHTML={{ __html: chat.user_text }}
                        />
                      </div>
                    )}
                    {chat.gpt_text && (
                      <div className="">
                        <div className="d-flex flex-row bg-white p-3 p-lg-4 p-md-4 shadow-sm rounded-3">
                          <div>
                            <img
                              className="listing-logo rounded-circle"
                              src={logoPath || LOGO}
                              alt=""
                            />
                          </div>
                          <span
                            className="mx-3"
                            dangerouslySetInnerHTML={{ __html: chat.gpt_text }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {chat._id && (
                  <div className="d-flex flex-lg-row flex-md-row flex-column align-items-lg-center align-items-md-center mt-1">
                    <div className="d-flex justify-content-evenly feedback-section rounded-5 p-2 ms-5">
                      <button className="border-0 p-0 m-0">
                        <ThumbsUp
                          className="d-flex"
                          size={16}
                          style={{
                            color:
                              feedback[chat._id] === "thumbsUp"
                                ? "#0a63cc"
                                : "#777",
                          }}
                          onClick={() =>
                            handleButtonClick(chat._id, "thumbsUp")
                          }
                        />
                      </button>
                      <div className="vr" />
                      <button className="border-0 p-0 m-0">
                        <ThumbsDown
                          className="d-flex"
                          size={16}
                          style={{
                            color:
                              feedback[chat._id] === "thumbsDown"
                                ? "#cc313d"
                                : "#777",
                          }}
                          onClick={() =>
                            handleButtonClick(chat._id, "thumbsDown")
                          }
                        />
                      </button>

                      {user_type === "Internal" ? (
                        <div className="d-flex align-items-center">
                          <div className="vr" />
                          <button className="border-0 p-0 m-0 ms-1">
                            <MessageCircle
                              className="d-flex"
                              size={16}
                              color="#777"
                              onClick={() => handleCommentClick(chat._id)}
                            />
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {comment[chat._id] && (
                      <div className="ms-0 ms-lg-3 ms-md-3 mt-3 mt-lg-0 mt-md-0 comment-box-main rounded d-flex flex-row">
                        <input
                          type="textarea"
                          className="comment-box border-0 bg-transparent"
                          placeholder="Comment..."
                          onChange={(e) => {
                            setCommentData(e.target.value);
                          }}
                        />
                        <button
                          className="bg-transparent border-0"
                          onClick={() => {
                            handleComment(chat._id);
                          }}
                        >
                          <div className="send-comment rounded-circle d-flex justify-content-center align-items-center">
                            <ArrowUp
                              className=""
                              size={12}
                              color="#fff"
                              style={{ marginRight: "1px" }}
                            />
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}

            {loading && !isTyping && (
              <div className="bg-white p-2 rounded-3 shadow-sm mt-3 deepkak-ke-project-ki-width">
                <p className="font-14 text-start m-0">
                  <img
                    className="listing-logo rounded-circle"
                    src={logoPath || LOGO}
                    alt=""
                  />
                  <i className="ms-3">Loading...</i>
                </p>
              </div>
            )}

            {isTyping && (
              <div className="font-16 text-start mt-3 deepkak-ke-project-ki-width">
                <div className="d-flex flex-row bg-white p-3 p-lg-4 p-md-4 shadow-sm rounded-3">
                  <div>
                    <img
                      className="listing-logo rounded-circle"
                      src={logoPath || LOGO}
                      alt=""
                    />
                  </div>
                  <span
                    className="mx-3 w-75"
                    dangerouslySetInnerHTML={{ __html: typingText }}
                  />
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />

          </div>
        </div>

        <div className="row input-style mx-auto">
          <div>
            {fileUpload && (
              <div className="row mx-auto">
                {Object.entries(progress).map(([fileName, fileProgress]) => (
                  <div
                    key={fileName}
                    className="position-relative col-lg-1 d-flex justify-content-center"
                  >
                    <img
                      className={`attachment-type ${fileProgress < 100 ? "blur" : ""
                        }`}
                      src={getFileIcon(fileName)}
                      alt="File Icon"
                    />
                    {fileUploading && fileProgress < 100 && (
                      <div className="position-absolute loader-overlay">
                        <div
                          class="spinner-border text-secondary attachment-spinner"
                          role="status"
                        ></div>
                      </div>
                    )}
                      <div className="position-absolute close-attachment rounded-circle d-flex justify-content-center align-items-center">
                        <button
                          className="border-0 bg-transparent d-flex justify-content-center align-items-center"
                          onClick={() => handleFileDelete(fileName)}
                        >
                          <X size={14} color="#1e1e1e" />
                        </button>
                      </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="d-flex flex-row p-0">
            <div className="col-2 col-lg-1 col-md-1 d-flex justify-content-center align-items-center">
              <div className="mb-3">
                <input
                  id="attachmentfiles"
                  className="form-control d-none"
                  type="file"
                  multiple
                  name="upload_document"
                  accept=".pdf, .doc, .docx"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
              </div>
              <label onClick={() => setShowModal(!showModal)} style={{ cursor: "pointer" }}>
                <Paperclip className="me-1" size={24} color="#000" />
              </label>
            </div>
            <div className="col-8 col-lg-10 col-md-10">
              <input
                type="text"
                className="border-0 w-100 h-100 bg-transparent preview-inputarea"
                placeholder="Type..."
                value={userQuery}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="col-2 col-lg-1 col-md-1 d-flex justify-content-center align-items-center pe-0">
              <button className="bg-transparent border-0" onClick={handleSend}>
                <div className="send-question rounded-circle d-flex justify-content-center align-items-center">
                  <Send className="" size={20} color="#fff" style={{ marginRight: "2px", marginTop: "1px" }} />
                </div>
              </button>
            </div>
          </div>
        </div>
        {showModal && (
          <div className="attachment-expand">
            <div className="bg-white rounded-4 shadow-sm border">
              <div className="me-2 mt-2">
                <div className="d-flex justify-content-end" onClick={()=>setShowModal(false)}>
                  <X
                    className="cursor-pointer"
                    size={20}
                    color="#777" />
                </div>
                <div className="px-3 pt-2 pb-3">
                  <div className="mb-3 d-flex flex-row align-items-center px-2">
                    <label htmlFor="urlInput" className="form-label m-0 font-14 fw-bold">URL</label>
                    <input
                      type="url"
                      className="form-control ms-3 url-input"
                      id="urlInput"
                      placeholder="Enter URL"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                    />
                    <button className="btn btn-sm btn-primary ms-3" onClick={handleScrape} disabled={urlloading} style={{ position: 'relative', width: '120px', height: '40px' }}>
                      {urlloading ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                          style={{ width: '1.5rem', height: '1.5rem' }}
                        ></div>
                      ) : (
                        'Scrap'
                      )}
                      {/* {urlloading ? (
                          <div className="position-absolute loader-overlay">
                            <div className="spinner-border text-secondary attachment-spinner" role="status"></div>
                          </div>
                        ) : (
                          'Scrap'
                        )} */}
                    </button>

                  </div>
                  <hr className="bg-f0 mb-2" />
                  <div className="d-flex flex-row align-items-center p-2 cursor-pointer upload-fc" onClick={() => {document.getElementById("attachmentfiles").click();}}>
                    <Upload
                      className=""
                      size={20}
                      color="#777"
                      style={{ marginRight: "1px" }}
                    />
                    <label htmlFor="" className="ms-3 cursor-pointer">Upload From Device</label>
                  </div>
                </div>
              </div>

              {/* <div className="">
                      <input
                        type="file"
                        className="form-control d-none"
                        id="fileUploadcomputer"
                      />
                    </div> */}
            </div>
          </div>

        )}

      </div>
    </div>
  );
}

export default Preview;
