import React, {useState} from 'react';
import listinglogo from '../images/listinglogo.png';
import { Menu, User } from 'react-feather';

const Header = ({ header_name, logo_header, toggleSidebar }) => {
  
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-0 header-style sticky-top d-flex justify-content-between">
      <div className="p-0 d-flex flex-row align-items-center">
      <Menu className='ms-4 d-block d-lg-none' size={20} color='#fff' style={{ cursor: 'pointer' }} onClick={toggleSidebar}/>
        {logo_header && <img className='ms-4 header-user-image rounded-circle' src={logo_header} alt="" />}
      <span className="navbar-brand mb-0 h1 fw-bold text-white ps-3">{header_name}</span>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        </div>
      </div>
      {/* <div className='me-5 bg-white rounded-circle header-user-image d-flex justify-content-center align-items-center'>
        <button className='btn bg-transparent border-0'>
          <User size={20}color='#1e1e1e' />
        </button>
      </div> */}
    </nav>
  );
};

export default Header;